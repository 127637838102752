/* ==========================================================================
   Accordion
   ========================================================================== */

/**
 * accordion with image
 */

.flipIn {
    animation: flipdown 0.5s ease both;
}

.c-accordion {
    max-width: $global-width;
    margin: auto;

    @include breakpoint(small only) {
        padding-bottom: rem(20);
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        margin-left: rem(20);

        @include breakpoint(medium) {
            margin-left: rem(80);
        }
    }

    &__section-headline {
        width: fit-content;
        margin-top: rem(20);

        @include breakpoint(large) {
            margin-top: rem(80);
        }
    }

    &__image {
        display: none;

        @include breakpoint(large) {
            display: block;
        }
    }

    &__list {
        list-style: none;

        @include breakpoint(medium) {
            margin-right: rem(40);   
        }
    }

    &__item {
        position: relative;
        transition: height 0.3s ease-in-out;

        @extend .flipIn;

        @for $i from 1 through 20 {
            &:nth-of-type(#{$i}) {
                animation-delay: #{$i * 0.25 + 0.25}s;
            }
        }

        > i {
            position: absolute;
            right: rem(30);

            &:before {
                content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='15' height='10' viewBox='0 0 32 24'><path style='fill:rgb%280, 141, 221%29' d='m0 0 16 24L32 0z'/></svg>");
                position: absolute;
                transition: all 0.25s ease-in-out;
                transform: rotate(180deg);
            }
        }

        > input[type="checkbox"] {
            position: absolute;
            cursor: pointer;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;

            @media only screen {
                &:checked {
                    & ~ .c-accordion__content {
                        margin-top: 0;
                        max-height: 0;
                        opacity: 0;
                        transform: translate(0, 1rem);
                    }

                    & ~ i {
                        &:before {
                         transform: rotate(360deg);
                        }
                    }
                }
            }
        }
    }

    &__title {
        font-size: rem(18);
        font-family: $header-font-family;
        letter-spacing: $paragraph-letter-spacing;
        font-weight: 500;
        background-color: inherit;
        margin: rem(20 40 20 0);
        cursor: pointer;
        transition: color 0.25s ease-in-out;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        @include breakpoint(medium) {
            margin: rem(20 0);
        }

        &:after {
            display: block;
            content: '';
            border-bottom: solid 1px $dark-gray;
            margin-top: rem(10);
        }
    }

    &__content {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease-in-out, opacity 0.65s ease-in-out;
        opacity: 1;
        transform: translate(0, 0);
    }
}