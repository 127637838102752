/* ==============================================================================
    Status Flag  Component. Used in the project results and project detail pages
   ========================================================================== */

.c-status {
    display: inline-block;
    padding: rem(7 18);
    color: $white;
    font-size: rem(14);
    font-weight: 700;
    line-height: 1.7;
    background: $primary-color;

    &.is-verkaufsbereit-mit-baufreigabe {
        background: $green;
    }

    &.is-verkaufsbereit-ohne-baufreigabe {
        background: $secondary-color;
    }

    &.is-verkauft {
        background: $red;
    }
}
