/* ==========================================================================
   Prazellen Overview
   ========================================================================== */

/**
 * parzellen-overview table
 *
 */

.c-parzellen-overview {

    @include breakpoint(large) {
        display: flex;
    }

    &__section-header {
        @include breakpoint(large) {
            width: 40%;
        }
    }

    &__section-headline {
        margin: rem(7 0 25 0);
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 15%;
        align-items: center;

        @include breakpoint(medium) {
            flex-direction: row;
        }
    }

    &__status {
        font-family: $header-font-family;
        font-size: rem(18);
        line-height: 1.7;
        font-weight: 300;

        dt {
            font-size: rem(24);
            line-height: 1;
        }

        dt:nth-child(1) {
            font-size: rem(36);
            font-weight: 400;
            line-height: 1.2;
        }
        
        dd {
            padding-left: rem(20);
        }

        dd:nth-child(2) {
            padding-bottom: rem(11);
            font-size: rem(28);
            font-weight: 400;
        }
    }

    &__detail {
        text-align: center;

        @include breakpoint(medium) {
            text-align: left;
        }

        span {
            font-size: rem(11);
            letter-spacing: .1em;
            text-transform: uppercase;
        }

        p {
            font-weight: 700;
            font-size: rem(15);
        }
    }

    .icon--pz-overview {
        width: rem(10);
        height: rem(10);
    }

    &__wrapper {
        @include breakpoint(large) {
            width: 60%;
        }
    }

    &__table {
        margin-top: rem(20);
        overflow: auto;

        table {
            table-layout: fixed;
            width: 155%;
            margin-bottom: rem(30);

            @include breakpoint(medium) {
                width: 100%;
            }
        }

        td {
            padding-right: rem(10);
        }

        td div {
            height: rem(56);
            display: flex;
            align-items: center;
        }
    }

    tr {
        &.u-row-hidden {
            td div {
                max-height: 0;
                box-sizing: border-box;
                overflow: hidden;
                transition: max-height 0.3s;
            }

            td {
                border-bottom: none;
            }
        }

        &.u-row-hidden-undo {     
            td div {
                max-height: rem(56);
                transition: max-height 0.6s;
            }
        } 
    }

    .icon--circle {
        display: inline-block;
        width: rem(14);
        height: rem(14);
        margin-right: rem(8);
        border-radius: 50%;

        &.is-reserviert {
            background-color: $yellow;
        }

        &.is-frei {
            background-color: $light-green;
        }
    }

    [data-togglebutton] {
        margin-top: rem(25);
    }
}
