html {
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}


// this is for fixing the footer to the bottom of the viewport (because of min-height not working in IE)
.l-page-wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}


.l-page-header,
.l-page-footer {
    width: 100%; // needed for firefox and edge display error
    flex: 0 0 auto;
}

.l-page-content {
    // add adding if no hero is present in the content area
    padding-top: rem(130);
    flex: 1 0 auto;
    width: 100%;

    &.has-hero {
        padding-top: 0;
    }
}

// We want to be able to collapse all paddings even on a background div
// Commenting this out for now.

/* .grid-background[class*="u-background--"] {
    padding: $typogrid * 4 0;
    margin: $typogrid * 4 0;

    & + & {
        padding-top: 0;
        margin-top: $typogrid * -8;
    }
} */

// prevents sklyine from having no background
.grid-background:last-child {
    margin-bottom: 0;
    padding-bottom: rem(150);
}

.grid-container {
    @include breakpoint(medium only) {
        max-width: calc(100vw - 2rem);
    }
}

.u-border-bottom:after {
    position: absolute;
    bottom: 0;
    content: "";
    height: rem(40);
    width: 100%;
    z-index: 2;
    background: linear-gradient(360deg, rgba(191, 191, 191, 0.18) 0%, rgba(196, 196, 196, 0) 36.23%);
}

//Hide Arrows From Input Number

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}