/* ==========================================================================
   CSS based slider
   ========================================================================== */

/**
 * c-slider
 * Borrowing ideas from https://css-tricks.com/can-get-pretty-far-making-slider-just-html-css/
 */

 .c-slider {
    margin: auto;

    @include breakpoint(medium) {
        min-width: rem(620);
    }

    @include breakpoint(large) {
        max-width: rem(900);
    }

    &__item {
        display: flex;
        justify-content: center;
    }

    &.c-gallery {

        a {
            position: relative;
        }

        .c-gallery__image-wrapper {
            width: rem(320); 
            height: rem(214);

            @include breakpoint (medium) {
                width: rem(640); 
                height: rem(428);
            }

            img {
                object-fit: contain;
            }
        }

        .icon--magnifying-glass {
            position: absolute;
            bottom: rem(3);
            left: rem(8);
            height: rem(25);
            width: rem(25);
            fill: $darkest-gray;
            background-color: $white;
            border-radius: 50%;
            border: 1px solid $white;
        }
    }
}

.c-slider-controls {
  
    &__button {
        &:hover {
            .c-slider-controls__icon {
                fill: $secondary-color;
            }
        }

        &:focus:not(:focus-visible) {
            outline: none;
        }
        //for keyboard user
        &:focus-visible {
            outline: 3px dashed $secondary-color;
        }
    }

    &__icon {
        width: rem(28);
        height: rem(28);
        fill: $secondary-color;
        transition: fill 0.3s ease-in-out;

        &--prev {
            transform: rotate(-180deg);
        }
    }

    &.glide__arrows {
        display: flex;
        justify-content: center;
        margin-bottom: rem(40);

        @include breakpoint(large) {
            position: absolute;
            top: rem(110);
            width: rem(1000);
            right: rem(-50);
            margin-bottom: 0;
            justify-content: space-between;
        }
    }

    &.glide__bullets {
        display: none;
        margin-top: rem(40);
        text-align: center;

        @include breakpoint(large) {
            display: block; 
        }
    }

    .glide__bullet {
        width: rem(9);
        height: rem(9);
        margin: 0 0.25em;
        border-radius: 50%;
        border: 2px solid transparent;
        background-color: $dark-gray;
        transition: all 300ms ease-in-out;
        cursor: pointer;

        &:focus:not(:focus-visible) {
            outline: none;
        }
        //for keyboard user
        &:focus-visible {
            outline: 3px dashed $secondary-color;
        }

        &:hover {
            border: 2px solid $primary-color;
            background-color: $primary-color;
        }

        &--active {
            background-color: $secondary-color;
        }
    }
}
