/* ==========================================================================
   results
   ========================================================================== */

/**
 * results for baugebiete, baugebiete highlights and referenzen
 *
 */

.c-results {
    width: 100%;
    margin-bottom: $global-margin;

    &__header {
       display: flex;
       flex-direction: column;
       align-items: center;
       margin-bottom: $global-margin;

       @include breakpoint (medium) {
            flex-direction: row;
            justify-content: space-between;
            align-items: end;
        }
    }

    &__filter {
        
        @include breakpoint (small only) {
            margin-top: rem(20);
        }

        select {
            width: rem(220);
            border: 1px solid $medium-gray;

            @include breakpoint (medium) {
                width: rem(305);
            }
        }
    }

    &__list {
        border-top: rem(3) solid $light-gray;
        list-style: none;

        @include breakpoint(medium) {
            border-left: rem(3) solid $light-gray;
        }
    }

    &__item {
        background-color: $white;
        border-bottom: rem(3) solid $light-gray;

        &:hover {
            .c-results__image {
                transform: scale(1.3);
            }
        }

        @include breakpoint(medium) {
            padding: rem(0 15);
            min-height: rem(440);
            border-right: rem(3) solid $light-gray;
        }

        a {
            &:focus:not(:focus-visible) {
                .c-results__item-header {
                    outline: none;
                }
            }
            //for keyboard user
            &:focus-visible {
                .c-results__item-header {
                    outline: 3px dashed $secondary-color;
                }
            }
        }
    }

    &__item-header {
        display: flex;
        flex-direction: column;
        max-width: 90%;
        margin: auto;
        border-bottom: rem(1) solid $light-gray;
        font-family: $header-font-family;
        font-size: rem(30);
        letter-spacing: $header-letter-spacing;
    }

    &__flag-wrapper {
        position: relative;
    }

    &__flag {
        position: absolute;
        top: 20%;
        z-index: 2;
        padding: rem(7 18);
        background: $primary-color;

        @include breakpoint (medium) {
            left: rem(-11);
        }

        &.is-verkaufsbereit-mit-baufreigabe {
            background: $green;
        }

        &.is-verkaufsbereit-ohne-baufreigabe {
            background: $secondary-color;
        }

        &.is-verkauft {
            background: $red;
        }

        &::after {
            @include breakpoint (medium) {
                position: absolute;
                bottom: -43%;
                left: 0%;
                display: block;
                content:'';
                width: 0;
                height: 0;
                border-top: rem(17) solid $secondary-color;
                border-left: rem(11) solid transparent;
            }
        }
    }

    &__status {
        margin: 0;
        color: $white;
        font-size: rem(12);
        font-weight: 700;
        line-height: 1.7;

        @include breakpoint(large) {
            font-size: rem(14);
        }
    }

    &__image-wrapper {
        margin: rem(66 0 35);
        overflow: hidden;
    }

    &__image {
        transform: scale(1);
        transition: opacity 0.3s ease-in-out, transform 3s ease-in-out;
    }

    &__headline {
        margin: rem(0 0 20 0);
        color: $black;
        font-weight: 300;
        text-align: center;
    }

    &__content {
        display:flex;
        flex-wrap: wrap;
        gap: 5%;
        max-width: 90%;
        margin: rem(0 auto 0);

        @include breakpoint (large) {
            gap: 10%;
            margin: rem(20 auto 0);
        }
    }

    &__info {
        flex: 1 0 45%;
        color: $black;
        text-align: center;
        font-size: rem(15);
        line-height: 1.7;
        font-weight: 700;

        span {
            font-size: rem(11);
            font-weight: normal;
            letter-spacing: .1em;
            text-transform: uppercase;
        }
    }

    &__lots {
        flex: 1 0 100%;
        padding-top: rem(20);
        margin-bottom: rem(20);
        border-top: 1px solid $light-gray;
        color: $secondary-color;
        text-align: center;
        font-size: rem(14);
        line-height: 170%;
        font-weight: 900;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        span {
            &::after{
                display: block;
                content: "";
                width: rem(140);
                border-bottom: solid 1px $secondary-color;
                margin: auto;
            }
        }
    }

    // Variations
    &--highlights {

        .c-results__list {
            border-top: none;
        }

        .c-results__item {
            padding-bottom: $global-margin;

            @include breakpoint(large) {
                border-bottom: none;
            }
        }

        .c-results__flag {
            display: none;
        }

        .c-results__content {
            margin-bottom: rem(20);

            @include breakpoint (medium down) {
                margin-top: rem(20);
            }
        }
    }

    .icon--bg-overview {
        width: rem(10);
        height: rem(10);
    }

    //referenzen variante
    &.c-results--referenzen {

        .c-results__item {
            min-height: fit-content;
        }
    }

}