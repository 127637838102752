/* ==========================================================================
hero
========================================================================== */

/**
* hero
* Hero Images with text, medium and big variant
*/

.c-hero {
    position: relative;

    &__background {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        overflow: hidden;

        &:after {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            background: linear-gradient(180deg, $light-blue 0%, rgba(255, 255, 255, 0) 35%);
            filter: blur(0px);
        }
    }

    &__background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        filter: opacity(0.4);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: rem(662);
        min-height: rem(400);
        padding: rem(0 15);
        margin: auto;
        color: $dark-blue;
        font-family: $header-font-family;
        font-weight: 300;
        text-align: center;

        &:after {
            position: absolute;
            bottom: 0;
            content: "";
            height: rem(15);
            width: 100%;
            z-index: 1;
            background: linear-gradient(
                180deg,
                $medium-blue,
                $medium-blue 75%,
                $primary-color 75%,
                $primary-color 100%
            );
        }

        @include breakpoint(large) {
            min-height: rem(600);
            margin: auto;
        }
    }

    &__heading {
        z-index: 1;
        margin-bottom: rem(20);
        font-size: rem(35);
        line-height: 120%;
        letter-spacing: $header-letter-spacing;
        text-shadow: rem(2) rem(2) rem(3) rgba(0, 0, 0, .3);
    }

    &__subheading {
        z-index: 1;
        margin-top: rem(-30);
        font-size: rem(18);
        font-family: $body-font-family;
        letter-spacing: 0.05em;
        line-height: rem(29);
        text-shadow: rem(2) rem(2) rem(3) rgba(0, 0, 0, .3);
    }

    .c-hero__button {
        z-index: 1;
        position: absolute;
        right: rem(-77);
        width: rem(205);
        transform: rotate(270deg);
        padding: rem(16 34);
        margin: 0;
        border-radius: 0;
        font-family: $body-font-family;
        background-color: $dark-blue;
    }

    .c-button__label {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: rem(14);
        line-height: rem(16);
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    .icon--dchevron {
        display: none;

        @include breakpoint (medium) {
            display: block;
            margin-right: rem(10);
            transform: rotate(90deg);
            width: rem(10);
            height: rem(13);
        }
    }

    .icon--arrow {
        z-index: 2;
        width: rem(30);
        height: rem(10);
        fill: $secondary-color;
    }

    //medium hero variant
    &.c-hero--medium {

        .c-hero__content {
            padding-top: rem(84);
            font-size: rem(35);
            font-weight: normal;

            @include fluid-type(320px, 663px, 22px, 35px);

            @include breakpoint(large) {
                min-height: rem(400);
            }

            p {
                line-height: rem(46);
            }
        }
    }

    &.c-hero--big {
         .c-hero__heading {
            // Mixin Values : $min_width, $max_width, $min_font, $max_font
            @include fluid-type(320px, 663px, 22px, 42px);
        }
    }
}

.c-hero--video {

    &:after {
        position: absolute;
        bottom: 0;
        content: "";
        height: rem(15);
        width: 100%;
        z-index: 2;
        background: linear-gradient(
            180deg,
            $medium-blue,
            $medium-blue 75%,
            $primary-color 75%,
            $primary-color 100%
        );
    }

    .c-hero__background {
        height: rem(400);
        position: relative;

        @include breakpoint(large) {
            height: rem(600);
        }
    }

    video {
        display: none;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        max-width: none;
        width: auto;
        height: rem(600);
        transform: translateX(-50%) translateY(-50%);
        object-fit: fill;

        @include breakpoint(large) {
            display: block;
        }
    }
}
