/* ==========================================================================
  Link
  ========================================================================== */

/**
* link component
* Specific link as component styling
*/

.c-link {
  
  // Icon variations
  &--icon {
    display: flex;
    align-items: center;
  }
  
  &__icon {
     margin-right: rem(5);
     
     svg {
       width: rem(12);
       height: rem(12);
       fill: currentColor;
     }
     
  }
  
  // Size variations
  &--medium {
    font-size: rem(18);
  }
}