.c-button {
    @include button;
    width: auto;
    font-weight: $global-weight-normal;
    font-size: rem(18);
    letter-spacing: 0.05em;
    line-height: rem(29);

    @include breakpoint(medium) {
        padding: rem(10 70);
    }

    @include fluid-type(150px, 300px, 16px, 18px);

     // Colors
    @each $name, $styles in $button-styles {
        &.c-button--#{$name} {
            @include button-style(map-get($styles, 'background'), map-get($styles, 'background-hover'), map-get($styles, 'text-color'));
        }
    }

    &.is-transparent {
        background: transparent;

        @each $name, $styles in $button-hollow-styles {
            &.c-button--#{$name} {
                border: $button-hollow-border-width solid map-get($styles, 'border-color');
                color: map-get($styles, 'text-color');
              
                &:hover, &:focus {
                    color: map-get($styles, 'text-hover');
                    border-color: map-get($styles, 'border-hover');
                    background: transparent;
                }
            }
        }
    }

    &.is-outlined {
        background: $white;

        @each $name, $styles in $button-hollow-styles {
            &.c-button--#{$name} {
                border: $button-hollow-border-width solid map-get($styles, 'border-color');
                color: map-get($styles, 'text-color');
              
                &:hover, &:focus {
                    color: map-get($styles, 'text-hover');
                    border-color: map-get($styles, 'border-hover');
                    background: map-get($styles, 'background-hover');
                }
            }
        }
    }

    // full width buttons
   &.is-fullwidth {
        @include button-expand;
    }

    // Disabled style
    &.is-disabled,
    &[is-disabled] {
        @include button-disabled;
        display: inline-block;  /* For IE11/ MS Edge bug */
        pointer-events: all;

        @each $name, $styles in $button-styles {
            &.c-button--#{$name} {
                background: $gray-blue;
            }
        }
    }  

    // Print style
    @media print {
        background: none;
        color: #000;
        border: 1px solid #000000;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}
