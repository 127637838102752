@media print {
    * {
        clip-path: none !important;
        box-shadow: none !important;
        text-shadow: none !important;
        background: transparent !important;
        color: #000 !important;
    }

    @page {
        size: A4;
        margin: 1cm 1cm 0.5cm;
    }

    html {
        font-size: 78%;
    }
 
    header, footer {
        display: none;
    }

    h3, h4 {
        page-break-after: avoid;  // these headlines need most of the time the content after themselves
    }

    a {
        text-decoration: none !important;
    }

    a[href]:after {
        content: none !important;
    }

    .grid-container {
        max-width: none;
    }

    .grid-background:last-child {
        padding-bottom: 0;
    }

    .c-nav, .c-hero, .c-pagefooter, .c-form, .c-toolbar, .c-slider-controls__button, .glide__slide--clone {
        display: none; 
    }

    .c-cta-image {
        &__background-image {
            display: none;
        }

        &__content {
            max-width: none;
        }
    }

    .c-key-facts {
        &__list {
            flex-wrap: nowrap;
        }
        &__item {
            min-width: none;
        }
    }

    .c-results {
        margin-bottom: 0;

        &__flag {
           top: 8%;
            
            &:after {
                display: none;
            }
        }

        &__status {
            font-weight: normal;
        }

        &__item {
            padding-bottom: 0;
        }
    }

    .c-accordion {
        .cell {
            width: 100%;
        }

        &__image {
            display: none;
        }
    }

    .c-team-overview {
        &__list {
            max-width: rem(840);
        }

        .c-card {
            width: rem(420);
        }
    }

    .c-projectstatus {
        &__item:after {
            display: none;
        }

        .icon--arrow_down {
            display: none
        }
    }

    .c-gallery {
        .glide__slides {
            width: rem(440) !important;
            transform: unset !important;
            flex-wrap: wrap;
        }

        .c-slider__item {
            width: rem(420) !important;
        }

        &__image-wrapper {
            width: rem(420) !important;
            height: auto !important;
            margin-bottom: rem(40);
        }
    }

    [data-slider="partnerlogos"] {
        .glide__slides {
            max-width: rem(910);
            flex-direction: row;
            transform: unset !important;
            flex-wrap: wrap;
        }

        .c-slider__item {
            width: rem(400) !important;
            margin-bottom: rem(40);
        }
    }
}
