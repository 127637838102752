table {
    border-collapse: collapse;

}

td {
    border-bottom: 1px solid $dark-gray;
}

th {
    font-weight: normal;
    font-size: rem(11);
    line-height: 1.7;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;
    border-bottom: 1px solid $darkest-gray;
    border-collapse: collapse;
}

td {
    font-size: rem(14);
    line-height: 1.62;
    letter-spacing: $paragraph-letter-spacing;
}

table {
    
    .c-button {
        margin: 0;
        padding: rem(5 0);
        width: rem(110);
        height: rem(35);
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.62;
        font-size: rem(14);
    }
}