/* ==========================================================================
   Toolbar
   ========================================================================== */

/**
 * toolbar shown under hero on baugebiet detail pages
 *
 */

.c-toolbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: $light-gray;
    max-width: $global-width;
    margin: auto;

    @include breakpoint(medium) {
        flex-wrap: nowrap;
        max-height: rem(60);
    }

    a {
        font-size: rem(14);
        letter-spacing: $paragraph-letter-spacing;
    }

    span {
        display: flex;
    }

    &__backlink {
        margin-left: rem(15);
        
        span {
            align-items: center;

            p {
                margin: 0;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        list-style: none;

        @media (prefers-reduced-motion: no-preference) {
            &.animation-hidden {
                height: 1px;
                animation: flipup 0.3s ease both;
            }

            &.animation-hidden-undo {
                animation: flipdown 0.3s ease both;
            }
        }

        @include breakpoint(medium) {
            width: unset;
            flex-direction: row;
            border-right: 2px solid $white;

            &.animation-hidden {
                height: unset;
                animation: unset;
            }
        }
    }

    &__item {
        display: flex;
        width: 100%;
        max-height: 60px;
        align-items: center;
        justify-content: center;
        border-top: 1px solid $white;
        background: $light-gray;

        a {
            padding: rem(18);
        }

        @include breakpoint(medium) {
            width: unset;
            border-top: none;
            border-left: 2px solid $white;
        }
    }

    .icon--arrow {
        width: rem(25);
        height: rem(60);
        margin-right: rem(20);
    }

    .icon--dots {
        width: rem(40);
        height: rem(60);
        margin-right: rem(20);
        cursor: pointer;
        fill: $secondary-color;
    }
}
