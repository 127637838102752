/* ==========================================================================
   Mobile Navigation
   ========================================================================== */

.c-mobile-nav {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
    background: rgba($primary-color, 0.95);
    opacity: 0;
	visibility: hidden;
    
    &__close {
        width: rem(40);
        height: rem(40);
        position: absolute;
        right: rem(30);
        top: rem(30);
        overflow: hidden;
        border: none;
        text-indent: 200%;
        z-index: 100;

        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: $white;
        }
    }

    &__wrapper {
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        perspective: 1200px;
    }

    &__list {
        margin: 0 auto;
        opacity: 0.4;
	    transform: translateY(-25%) rotateX(35deg);
	    transition: transform 0.5s, opacity 0.5s;
        list-style: none;
    }

    &__item {

        &.active .c-mobile-nav__link,
        .c-mobile-nav__link:hover,
        .c-mobile-nav__link:focus {
            &:after {
                opacity: 1;
                width: 100%;
            }
        }
    }

    &__link {
        font-family: $header-font-family;
        display: inline-block;
        padding: rem(6 0 8);
        color: $white;
        @include fluid-type(320px, 1020px, 26px, 50px);
        line-height: 1.7;

        &:hover {
            color: $white;
        }

        &:after {
            content: "";
            z-index: -1;
            position: relative;
            top: rem(-7);
            display: block;
            margin: 0 auto;
            opacity: 0;
            width: 0;
            height: 2px;
            background: #fff;
            transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0, 1),width 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        }
    }

    &--initialized {
        transition: opacity 0.5s, visibility 0s 0.5s;
    }

    &.is-open {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s;
        z-index: 100;

        .c-mobile-nav__list {
            opacity: 1;
            transform: rotateX(0deg);
        }
    }

    &.is-close {
        .c-mobile-nav__list {
            transform: translateY(25%) rotateX(-35deg);
        }
    }

    &__button {
        z-index: 3;
        position: relative;
        top: rem(-1);
        right: rem(-12);
        display: flex;
        align-items: center;
        margin: 0 0 0 auto;
        color: $primary-color;
        font-size: rem(16);
        font-family: $header-font-family;
        text-transform: uppercase;
        letter-spacing: rem(1);
        transition: top 0.75s, border-color ease-in-out 0.3s;
        padding: rem(8 12);
        border: 2px solid transparent;

        &.is-outside,&.is-slidingout, &.is-sticky {
            position: fixed;
            top: rem(27);
            right: rem(21);
            border-color: get-color(primary);
            background-color: $white;
        }
        &.is-outside {
            top: rem(-50);
            transition-duration: 0s;
        }
        &.is-slidingout {
            top: rem(-50);
        }
        
        @include breakpoint(large) {
            @include visually-hidden;
        }

        svg {
            display: block;
            position: relative;
            left: 0;
            width: rem(26);
            height: rem(25);
            fill: $primary-color;
            margin-left: rem(10);
            transition: left ease-in-out 0.5s;
    
            @include breakpoint(medium) {
                margin-left: rem(15);
            }
        }
    
        &-animatepath {
            transform: translateX(0);
            transition: transform ease-in-out 0.5s;
        }

        &:focus:not(:focus-visible) {
            outline: none;
        }
        //for keyboard user
        &:focus-visible {
            outline: 3px dashed $primary-color;
        }

        &:hover, &:focus {
            svg {
                left: rem(-5);

                .c-mobile-nav__button-animatepath {
                    transform: translateX(rem(-9));
                }
            }
        }
    }
}