/* ==========================================================================
   Project Status
   ========================================================================== */

/**
 * projectstatus timeline
 */


.c-projectstatus {
    margin: rem(0 auto 100);

    &__timeline {
        position: relative;
    }

    //vertical line
    &__timeline::after {
        position: absolute;
        top: 0;
        bottom: 0;
        content: '';
        width: rem(2);
        margin: rem(0 0 -100 -1);
        background: $black;
    }

    &__list {
        list-style: none;
    }

    &__item {
        position: relative;
        padding: rem(15 30);
      
        @media (prefers-reduced-motion: no-preference) {
            &.u-visually-hidden {
                position: unset;
                width: auto;
                height: auto;
                max-height: 0;
                clip: unset;
                padding: rem(0 30);
                transition: all 0.3s ease-out;
            }

            &.u-visually-hidden-undo { 
                max-height: rem(1000);
                transition: all 0.3s ease-in;
            } 
        }
    }

    //horizontal line
    &__item::before {
        position: absolute;
        right: rem(5);
        bottom: rem(6);
        content: '';
        width: 100%;
        height: rem(1);
        background: $dark-gray;
    }

    // circle
    &__item::after {
        position: absolute;
        bottom: 0;
        left: rem(-7);
        content: '';
        width: rem(14);
        height: rem(14);
        border: 2px solid $black;
        border-radius: rem(16);
        background: $black;
    }
    
    &__date {
        margin: (0 0 5 10);
        font-family: $header-font-family;
        font-size: rem(18);
        font-weight: 500;
        line-height: rem(24);
        letter-spacing: $header-letter-spacing;

        @include breakpoint (medium) {
            margin: rem(0 0 5 40);
        }
    }   

    &__details {
        margin: rem(0 0 23 0);

        @include breakpoint (medium) {
            margin-left: rem(40);
        }
    }   

    .icon--arrow_down {
        position: absolute;
        bottom: rem(-100);
        left: rem(-4);
        padding: rem(3);
        border: solid $black;
        border-width: rem(0 2 2 0);
        transform: rotate(45deg);
    }

    .c-button {
        margin: rem(50 0 0 30);
        padding: rem(10 40);

        @include breakpoint (medium) {
            margin: rem(50 0 0 52);
            padding: rem(10 70);
        }
    }
}
