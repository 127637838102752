/* ==========================================================================
   Contact Info
   ========================================================================== */

.c-contact-info {
    display: none;
   
    @include breakpoint(large) {
        display: block;
        margin-top: rem(15);
        color: $primary-color;
        text-transform: uppercase;
        letter-spacing: $paragraph-letter-spacing;
    }

    p {
        margin: 0;
        font-size: rem(12);
    }

    &--footer {
        list-style: none;
        margin: rem(40 auto 0);

        @include breakpoint(medium) {
            margin: rem(0 100 0 60);
        }

        .c-contact-info__item {
            color: $white;
            letter-spacing: $paragraph-letter-spacing;
        }

        .c-contact-info__metadata {
            dd {
                margin: rem(0 0 0 55);
                border: none;
            }
        }
    }
}
