/* ==========================================================================
   Benefits
   ========================================================================== */

/**
 * list of benefits
 */

.c-benefits {

    &__section-headline {
        display: flex;
        margin-bottom: rem(40);

        @include breakpoint(large) {
            position: relative;
            top: 16%;
            flex-wrap: wrap;
            margin-bottom: 0;
        }

        .c-benefits__headline {
            width: rem(120);

            @include breakpoint(large) {
                margin-left: rem(10);
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style-type: none;

        @include breakpoint(large) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            align-items: unset;
        }
    }

    &__number {
        color: $primary-color;
        font-size: rem(160);
        font-family: $header-font-family;
        font-weight: 100;

        @include fluid-type(300px, 500px, 100px, 160px);

        @include breakpoint(small only) {
            align-self: center;
        }
    }

    &__headline {
        margin: rem(20 0);   
        color: $primary-color;
        font-family: $header-font-family;
        font-size: rem(24);
        line-height: rem(32);
        letter-spacing: $header-letter-spacing;
        text-transform: uppercase;

        @include fluid-type(300px, 500px, 18px, 24px);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint(large) {
            align-items: flex-start;
        }

        .badge {
            display: flex;
            width: rem(40);
            height: rem(40);
            justify-content: center;
            align-items: center;

            @include breakpoint(large) {
                width: rem(50);
                height: rem(50);
            }
        }
    } 

    &__position {
        font-family: $header-font-family;
        font-size: rem(24);

        @include fluid-type(300px, 500px, 18px, 24px);
    }
 
    @include breakpoint(large) {
        .pos-1 {
            grid-column-start: 2;
            grid-row-start: 1;
        } 
        
        .pos-2 {
            grid-column-start: 3;
            grid-row-start: 1;
        }
        
        .pos-3 {
            grid-column-start: 1;
            grid-row-start: 2;
        }
        
        .pos-4 {
            grid-column-start: 2;
            grid-row-start: 2;
        }
        
        .pos-5 {
            grid-column-start: 3;
            grid-row-start: 2;
        }  
    }

    &__text {
        line-height: rem(29);
    }

    &__headline, &__text {
        max-width: rem(500);
        text-align: center;

        @include breakpoint(large) {
            max-width: rem(300);
            text-align: left;
        }
    }

    .icon--slim-arrow {
        display: none;

        @include breakpoint(large) {
            display: block;
            fill: $primary-color;
            max-width: rem(195);
            height: rem(10);
            margin-top: rem(20);
        }
    }
}
