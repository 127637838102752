/* ==========================================================================
cta Image
========================================================================== */

/**
* cta Image
* Images with text and call to action butotn
*/

.c-cta-image {
    position: relative;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: rem(400);
        max-width: 80%;
        margin: auto;
        text-align: center;

        @include breakpoint(medium) {
            max-width: rem(600);
        }

        @include breakpoint(large) {
            min-height: rem(600);
        }

        &:after {
            content: "";
            position: absolute;
            height: rem(15);
            width: 100%;
            bottom: 0;
            z-index: 1;
            background: linear-gradient(
                180deg,
                $medium-blue,
                $medium-blue 75%,
                $primary-color 75%,
                $primary-color 100%
            );
        }
    }

    &__heading {
        z-index: 1;

        @include breakpoint(large) {
            margin-top: rem(-60);
            margin-bottom: rem(40);
        }
        
        p {
            font-family: $header-font-family;
            line-height: rem(46);
            font-size: rem(35);
            letter-spacing: $header-letter-spacing;

            @include fluid-type(320px, 663px, 22px, 35px);
            
            @include breakpoint (small only) {
                line-height: rem(30);
            }
        }
    }

    &__content-text {
        z-index: 1;

        @include breakpoint(large) {
            margin-bottom: rem(20);
        }

        p {
            line-height: rem(24);

            @include fluid-type(320px, 663px, 12px, 16px);

            @include breakpoint(large) {
                line-height: rem(29);
            }
        }
    }

    .c-button {
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.7);

        &:hover {
            background-color: rgba(255, 255, 255, 0.7) !important;
        }
    }

    &__background-image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: bottom;
    }
}
