/* ==========================================================================
   Form select
   ========================================================================== */

/**
 * c-form-select
 * Add default cross-browser consistent styles for <select>
 */

.c-form-select {
    $height: ($input-font-size * unitless-calc($input-line-height)) + (get-side($input-padding, 'top') + get-side($input-padding, 'bottom')) - rem-calc(1);

    appearance: none;
    background-color: $select-background;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, $input-background 0%, $light-gray 100%);

    background-position: right rem(11) top 50%, 0 0;
    background-repeat: no-repeat, repeat;
    background-size: rem(10) auto, 100%;
    border: $input-border;
    border-radius: $select-radius;
    color: $input-color;
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    height: $height;
    line-height: $input-line-height;
    margin: 0 0 $form-spacing;
    padding: $input-padding ($form-spacing + rem(10)) $input-padding $form-spacing;

    // Focus state
    &:focus,
    &:hover {
        background-color: $input-background-focus;
        border: $input-border-focus;
        box-shadow: $input-shadow-focus -moz-mac-focusring;
        outline: none;
    }

    // Disabled state
    &:disabled {
        background-color: $input-background-disabled;
        cursor: $input-cursor-disabled;
    }

    // Hide the dropdown arrow shown in newer IE versions
    &::-ms-expand {
        display: none;
    }

    &[multiple] {
        background-image: none;
        height: auto;
    }

    option {
        font-weight: 400;
    }
}
