/* roboto-regular latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local("Roboto Regular"), local("Roboto-Regular"), url("roboto-v27-latin-regular.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("roboto-v27-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-300 latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
    src: local("Roboto 300"), local("Roboto 300"), url("roboto-v29-latin-300.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("roboto-v29-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-slab-regular latin */
@font-face {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local("Roboto Slab Regular"), local("RobotoSlab-Regular"),
        url("roboto-slab-v13-latin-regular.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("roboto-slab-v13-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-slab-300 latin */
@font-face {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
    src: local("Roboto Slab 300"), local("RobotoSlab-300"), url("roboto-slab-v13-latin-300.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("roboto-slab-v13-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-slab-100latin */
@font-face {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 100;
    font-display: fallback;
    src: local("Roboto Slab 100"), local("RobotoSlab-100"), url("roboto-slab-v16-latin-100.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("roboto-slab-v16-latin-100.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-slab-500 - latin */
@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 500;
    font-display: fallback;
    src: local("Roboto Slab 500"), local("RobotoSlab-500"), url("roboto-slab-v16-latin-500.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("roboto-slab-v16-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root {
    accent-color: $primary-color;
}

p {
    font-size: $paragraph-font-size;
    letter-spacing: $paragraph-letter-spacing;
}

.c-lead {
    font-family: $lead-font-family;
    font-weight: $lead-font-weight;
    color: $lead-font-color;
    font-size: $lead-font-size;
    line-height: $lead-lineheight;
    text-align: center;
}

ul {
    margin: 0;
}

dl dt {
    margin: 0;
    font-weight: unset;
}

dt {
    float: left;
    clear: left;
    margin: 0;
}

dd {
    margin: rem(0 0 0 50);
    border-left: 1px solid $dark-gray;
}


// show href value when a tag has no text value (https://twitter.com/AllThingsSmitty/status/946381631836901377)
a[href^="http"]:empty::before {
    content: attr(href);
}

a {
    transition: color 0.5s;
    color: $secondary-color;

    &:hover {
        color: $dark-blue;
    }

    &:focus:not(:focus-visible) {
        outline: none;
    }
    //for keyboard user
    &:focus-visible {
        outline: 3px dashed $secondary-color;
        color: $dark-blue;
    }
}

figure {
    margin: 0 0 $global-margin;

    img {
        display: block;
        margin: 0 auto;
    }
}

legend {
    padding: rem(0 4);
    margin-left: rem(-4);
}

label {
    letter-spacing: 0.01em;
    text-transform: uppercase;
}

input, select, textarea {
    &:focus:not(:focus-visible) {
        box-shadow: none;
    }
    //for keyboard user
    &:focus-visible {
        box-shadow: 1px 1px 5px rgba(1, 1, 0, .7);
    }
}

button {
    &:focus:not(:focus-visible) {
        box-shadow: none;
    }
    //for keyboard user
    &:focus-visible {
        outline: 3px dashed $secondary-color;
    }
}