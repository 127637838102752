/* ==========================================================================
   navigation
   ========================================================================== */

/**
 * Outer navigation
 */

 .c-nav {

    // control visibility
    @include visually-hidden;

    @include breakpoint(large) {
        @include visually-hidden-undo;
    }
    
    &__list {
        list-style: none;
    }

    &__item {
        display: inline-block;
        margin-left: rem(30);

        @media all and (min-width: rem(1100)) {
            margin-left: rem(60);
        }
    }

    &__link {
        display: flex;
        height: rem(90);
        align-items: center;
        color: $body-font-color;
        font-size: $nav-font-size;

        &:hover {
            color: $primary-color;
        }

        &.active {
            > .c-nav__link-text:after {
                transform: scaleX(1);  
            }
        }

        &:hover {
            > .c-nav__link-text:after {
                transform: scaleX(1);
            }
        }
    }

    &__link-text {
        &:after {
            display: block;
            content: '';
            border-bottom: solid 2px $secondary-color;
            transform: scaleX(0);  
            transition: transform 250ms ease-in-out;
        }
    }
}
