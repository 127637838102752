/* ==========================================================================
  team-overview
   ========================================================================== */

.c-team-overview {

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
        max-width: rem(450);

        @include breakpoint(large) {
            max-width: rem(900);
            justify-content: space-between;

        }
    }
}
