/* ==========================================================================
   header layout
   ========================================================================== */

/**
 * base styles
 */

.l-page-header {
    position: absolute;
    z-index: 3;   
    
    @include breakpoint(medium down) {
        padding-top: rem(20);
    }

    @include breakpoint(large) {
        padding-left: $header-side-margin;
        padding-right: $header-side-margin;
    }

    &.has-video-hero {
        background: rgba(255,255,255,0.8);
    }
}
