/* ==========================================================================
   Form
   ========================================================================== */

/**
 * Forms
 * 
 */

.c-form {
    @include breakpoint(large) {
        margin: auto;
    }

    &__heading {
        max-width: rem(800);
        margin: auto;
    }

    &__header {
        margin-top: rem(60);

        @include breakpoint(large) {
            display: flex;
            margin: rem(70 auto 40);
            justify-content: space-between;
        }
    }

    &__subline {
        line-height: rem(29);

        @include breakpoint(large) {
            text-align: start;
        }
    }

    fieldset {
        @include breakpoint(large) {
            width: rem(1010);
        }
    }

    &__subheading {
        font-family: $header-font-family;
        font-weight: 500;
    }

    input, select {
        height: rem(50);
        margin-bottom: rem(20);
        padding: rem(0 30 0 20);
    }

    select {
        background-size: rem(15 10);
    }

    textarea {
        min-height: rem(150);

        @include breakpoint(large) {
            min-height: rem(405);
        }
    }

    button {
        width: 100%;

        @include breakpoint(large) {
            width: rem(305);
        }
    }


    input {
        &:focus:not(:focus-visible) {
            box-shadow: none;
        }
        //for keyboard user
        &:focus-visible {
            outline: none;
            box-shadow: 1px 1px 5px rgba(1, 1, 0, .7);
        }
    }

    .c-checkbox {
        display: flex;
        justify-content: center;

        @include breakpoint(large) {
            max-width: rem(305);
            margin: auto;
        }

        &__label {
            @include breakpoint(large) {
                max-width: rem(265);
            }
        }

        input {
            width: rem(20);
            height: rem(20);
        }
    }
}

// Variante für Baugebiet-Detailseite
.c-form--baugebiete {
    @include breakpoint(medium down) {
        position: relative;
        padding-bottom: rem(130);
    }

    .c-form__smallinput {
        @include breakpoint(large) {
            .c-form__inputgroup {
                display: flex;
                gap: 10%;
            }

            input {
                width: rem(80);
            }
        }
    }

    fieldset {
      .cell:nth-child(2n) {
          margin-top: 0;

          @include breakpoint(large) {
              margin-top: rem(48);
          }
      }
    }

    .c-button {
        @include breakpoint(medium down) {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .c-checkbox {
        @include breakpoint(medium down) {
            position: absolute;
            bottom: rem(90);
            width: 100%;
            margin: auto;
        }
    }

    textarea {
        @include breakpoint(large) {
            min-height: rem(230);
        }
    }
}

// Variante für allgemeines Kontaktformular
.c-form--general {
    @include breakpoint(medium down) {
        position: relative;
        padding-bottom: rem(130);
    }

    fieldset {
      .cell:nth-child(2n) {
          margin-top: 0;

          @include breakpoint(large) {
              margin-top: rem(48);
          }
      }

    }

    .c-checkbox {
        @include breakpoint(medium down) {
            position: absolute;
            bottom: rem(90);
            width: 100%;
            margin: auto;
        }
      
        @include breakpoint(large) {
            margin-top: rem(30);
        }
    }

    .c-button {
        @include breakpoint(medium down) {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    textarea {
        @include breakpoint(large) {
            min-height: rem(140);
        }
    }
}

// Variante für Kontaktformular ohne kontakt card
.c-form--no-contact {
    @include breakpoint(medium down) {
        position: relative;
        padding-bottom: rem(130);
    }

    .c-form__header {
        max-width: rem(585);
        margin: rem(0 auto 100);
        justify-content: center;
    }

    .c-form__subline {
        margin-top: rem(40);
        text-align: center;
    }
}
