/* ==========================================================================
   Downloads
   ========================================================================== */

/**
 * list of downloads
 */

.c-downloads {

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        list-style: none;
    } 

    &__item {
        display: flex;
        gap: rem(30);
        margin-bottom: rem(20);

        a {
            font-family: $header-font-family;
            font-size: rem(18);
        }

        p {
            color: $darkest-gray;
            font-size: rem(14);
            font-weight: 300;
            line-height: rem(16);
        }
    }

    .icon--download {
        width: rem(45);
        height: rem(45);
        fill: $secondary-color;
        transition: fill 300ms ease-in-out;


        &:hover {
            fill: $secondary-color;
        }
    }
}