/* ==========================================================================
   Footer layout
   ========================================================================== */

/**
 * base styles
 */

.l-page-footer {
    padding-top: rem(30);
    background-color: $dark-blue;

    @include breakpoint(medium) {
        padding-top: rem(70);
    }
}

.c-footer__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: rem(40);

    @include breakpoint(medium) {
        flex-direction: row;
    }
}
