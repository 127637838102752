/* ==========================================================================
   logo
   ========================================================================== */

.c-logo {
    display: block;

    &__image {
        display: block;
        height: auto;
    }

    &--header {

        .c-logo__image {
            width: rem(90);
            height: rem(48);
    
            @include breakpoint(large) {
                width: rem(150);
                height: rem(82);
            }
        }
    }

    &--footer {
        fill: $gray-blue;
        margin: auto;

        .c-logo__image {
            position: relative;
            top: rem(-10);
            width: rem(167);
        }

        @include breakpoint(large) {
            margin: 0;

            .c-logo__image {
                top: 0;
            }
        }
    }
}
