/* ==========================================================================
   key-facts
   ========================================================================== */

/**
 * key facts with counter
 */

.c-key-facts {
    
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        list-style: none;
    }

    &__item {
        min-width: rem(300);
        text-align: center;


        &:hover {
            > .c-key-facts__text:after {
                transform: scaleX(2);
            }
        }
    }

    &__number {
        margin-bottom: rem(20);
        color: $primary-color;
        font-family: $header-font-family;
        font-weight: 100;
        font-size: rem(72);
        line-height: rem(117);
        @include fluid-type(150px, 800px, 48px, 72px);

        @include breakpoint (small only){
            margin-bottom: 0;
        }
    }

    &__text {
        font-size: rem(18);
        @include fluid-type(150px, 800px, 16px, 18px);


        &:after {
            display: block;
            content: '';
            width: rem(30);
            height: rem(20);
            border-bottom: solid 1px $black;
            transform: scaleX(1);  
            transition: transform 250ms ease-in-out;
            margin: auto;
        }
    }
}
