@charset "UTF-8";
// http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
    width: device-width;
}

// import modnern-normalize (https://github.com/sindresorhus/modern-normalize)
@import "node_modules/modern-normalize/modern-normalize";

// Lightbox Tool
@import "node_modules/glightbox/dist/css/glightbox";

// import foundation settins and scss
@import "node_modules/foundation-sites/scss/util/util";
@import "functions";
@import "settings";
@import "mixins";
@import "node_modules/foundation-sites/scss/foundation";

// Foundation Feature Includes
@include foundation-global-styles;
@include foundation-xy-grid-classes(
    // include only those you need...
    $base-grid: true,
    $margin-grid: true,
    $padding-grid: true,
    $block-grid: false,
    $collapse: false,
    $offset: true,
    $vertical-grid: false,
    $frame-grid: false
);
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
//@include foundation-accordion;
//@include foundation-accordion-menu;
@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;
@include foundation-flex-classes;

// Local Imports
@import "lazyload";
@import "layout";
@import "typography";
@import "buttons";
@import "tables";
@import "forms/form-select";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core.scss";

// Components
@import "components/footer-nav";
@import "components/nav";
@import "components/mobile-nav";
@import "components/logo";
@import "components/contact-info";
@import "components/downloads";
@import "components/accordion";
@import "components/projectstatus";
@import "components/hero";
@import "components/key-facts";
@import "components/cta-image";
@import "components/benefits";
@import "components/results";
@import "components/parzellen-overview";
@import "components/form";
@import "components/svgmap";
@import "components/slider";
@import "components/card";
@import "components/link";
@import "components/masonry";
@import "components/team-overview";
@import "components/toolbar";
@import "components/status";

// Layouts
@import "layouts/pageheader";
@import "layouts/pagefooter";

// Overrides - should be loaded last
@import "print";
@import "utils";
