/* ==========================================================================
   SVG Map Component
   ========================================================================== */

.c-svgmap {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
    max-width: rem(450);
    margin: auto;

    svg {
        .str3 {
            stroke: $darkest-gray;
            stroke-width: 0.35;
        }
        .str4 {
            stroke: $darkest-gray;
            stroke-width: 0.35;
        }
        .fil1 {
            fill: none;
            fill-rule: nonzero;
        }
        .fil0 {
            fill: get-color('light-gray');
            fill-rule: nonzero;
        }
        .has-result {
            fill: $secondary-color;
            cursor: pointer;
            transition: fill .3s ease-in-out;

            &:hover {
                fill: lighten($secondary-color, 20%);
            }
        }

        .is-active {
             fill: lighten($secondary-color, 20%);
        }

    }

    &__heading {

       @include breakpoint(small only) {
          text-align: center;
       }

       @include breakpoint(medium) {
          margin-top: rem(145);
       }
    }

    &__infobox {
        position: absolute;
        z-index: 2;
        display: none;
        padding: $global-padding $global-padding $global-padding / 2;
        background-color: $white;
        border: rem(1) solid $medium-gray;
        border-radius: $global-radius;
        box-shadow: rem(1) rem(1) rem(5) rem(0) rgba(0, 0, 0, 0.2);

        p {
            text-transform: uppercase;
            text-align: center;
            letter-spacing: rem(2);
            min-width: rem(250);

            span {
                padding-bottom: rem(10);
                border-bottom: rem(1) solid $secondary-color;
            }
        }

        a {
            color: $body-font-color;

            &:hover, &:focus {
              color: $secondary-color;
            }
        }

    }

    &__list {
       list-style: none;
       margin: $global-margin*2 0;

       @include breakpoint(medium) {
          max-width: rem(300);
       }

       li {
         text-transform: uppercase;
         padding: rem(10 0);
         border-bottom: rem(1) solid $secondary-color;

         svg {
           width: rem(12);
           height: rem(12);
           fill: $secondary-color;
         }

         a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $body-font-color;

            &:hover, &:focus {
              color: $secondary-color;
            }
         }

       }
    }

    &__buttons {
        position: absolute;
        top: rem(3);
        right: rem(10);

        svg {
            width: rem(12);
            height: rem(12);
            fill: $dark-gray;
        }
    }
}
