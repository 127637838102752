/* ==========================================================================
   masonry gallery
   ========================================================================== */



 .c-masonry {
    max-width: rem(1060);
    margin: auto;
    
    ul {
        list-style-type: none;
        display: grid;
        grid-template-columns: [first] 50% [second] 50%;
        grid-template-rows: 178px 178px 356px;

        @include breakpoint(large) {
            grid-template-columns: [first] 31% [second] 31% [third] 38%;
            grid-template-rows: 178px 178px;
        }
    }

    li {
        margin: 0;
        overflow: hidden;
    }

    img {
        height: 100%;
        width: 100%;
        display: block;
        object-fit: cover;
        transition: transform 1s ease-in-out, opacity 0.3s ease-in-out;
    }

    a {
        &:focus:not(:focus-visible) {
            img {
                outline: none;
            }
        }
        //for keyboard user
        &:focus-visible {
            img {
                outline: 3px dashed $secondary-color;
                outline-offset: -4px;
            }
        }
    }

    a:hover {
        img {
            transform: scale(1.2);
        }
    }

    // Items settings

    li:nth-child(3n) {
        grid-column-start: 1;
        grid-row-start: 2;
    }

    li:nth-child(4n) {
        grid-column-start: 2;
        grid-row-start: 2;
    }

    li:nth-child(5n) {
        grid-column-start: 1;
        grid-column-end: span 2;
        grid-row-start: 3;
    }

    @include breakpoint(large) {
        li:nth-child(3n) {
            grid-column-start: 1;
            grid-row-start: 2;
        }

        li:nth-child(4n) {
            grid-column-start: 2;
            grid-row-start: 2;
        }

        li:nth-child(5n) {
            grid-column-start: 3;
            grid-row-start: 1;
            grid-row-end: span 2;
        }
    }
}
