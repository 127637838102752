/* ==========================================================================
  Card
   ========================================================================== */

/**
 * card with contactdata
 *
 */

.c-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(medium) {
       flex-direction: row;
    }

    &__image {
        width: rem(150);
        height: rem(100);
        margin: 0;

        @include breakpoint(medium) {
            margin-right: rem(36);
        }
    }

    &__contact-infos {

        @include breakpoint (small only) {
            margin-top: rem(10);
        }

        dl {
            margin-top: rem(17);
        }

        dd {
            margin-left: rem(80);
            border: none;
            font-size: rem(14);
            line-height: rem(18);
            letter-spacing: $paragraph-letter-spacing;
        }

        dd:nth-child(2) a {
            color: $body-font-color;
        }
    }

    &__heading {
        margin-bottom: 0;
        font-family: $header-font-family;
        font-size: rem(18);
        letter-spacing: $header-letter-spacing;
    }

    &__subheading {
        margin-bottom: rem(10);
        font-size: rem(14);
        font-weight: 300;
        line-height: rem(16);
    }

    &__contact-info {
        font-family: $header-font-family;
        font-size: rem(14);
        line-height: rem(18);
        letter-spacing: $paragraph-letter-spacing;
    }

    //partnerslider variant 

    &.c-card--partner {
        width: rem(435);
        justify-content: center;

        .c-card__image {
            
            img {
                object-fit: contain;
            }
        }
    }

    //form variant
    &.c-card--form {
        justify-content: center;
        width: rem(330);
        margin: rem(0 auto 40);

        @include breakpoint(large) {
            margin: 0;
        }

        .c-card__image {
            overflow: hidden;
            border-radius: 50%;
            width: rem(72);
            height: rem(72);
            align-self: center;

            @include breakpoint(medium) {
                margin-right: rem(30);
                align-self: flex-start;
            }

            img {
                width: auto;
                height: 100%;
                object-fit: cover;
            }
        }

        .c-card__heading {
            margin-bottom: rem(10);
        }

        .c-card__contact-infos {
            max-width: rem(215);
    
            dd a {
                color: $body-font-color;
            }
        }
    }

    //team variant
    &.c-card--team {
        margin-bottom: rem(52);
        width: rem(450);
        justify-content: space-around;

        @include breakpoint (medium) {
            align-items: flex-start;
        }

        .c-card__image {
            overflow: hidden;
            border-radius: 50%;
            width: rem(100);
            height: rem(100);
            margin: 0;

            @include breakpoint (small only) {
                margin-bottom: rem(20);
            }

            img {
                width: auto;
                object-fit: cover;
            }
        }

        .c-card__contact-infos {
            width: rem(300);
        }
    }
}
